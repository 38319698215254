<template>
	<!-- 账单记录 -->
	<div class="store-fund-bill">
		<div class="sfb-top">
			<div class="sfb-top-money">
				<div class="sfb-top-money-li">
					<div>可用店铺余额（元）</div>
					<div class="sfbt-price">
						<span v-if="money">￥{{money.bank}}</span>
						<!-- <i class="ri-arrow-drop-right-line ri-lg"></i> -->
					</div>
				</div>
				<div class="sfb-top-money-li">
					<div>冻结金额（元）</div>
					<div class="sfbt-price">
						<span v-if="money">￥{{money.frozen}}</span>
						<!-- <i class="ri-arrow-drop-right-line ri-lg"></i> -->
					</div>
				</div>
				<div class="sfb-top-money-li">
					<div>待结算金额（元）</div>
					<div class="sfbt-price">
						<span v-if="money">￥{{money.wait_settled}}</span>
						<!-- <i class="ri-arrow-drop-right-line ri-lg"></i> -->
					</div>
				</div>
			</div>
			
			<div class="sfb-search">
				<a-space>
					<a-range-picker v-model:value="search.time" />
					<a-button type="primary" @click="getBillRecord(1,info.limit)">筛选</a-button>
				</a-space>
			</div>
			
		</div>
		
		<div class="el-content">
			<div class="kd-pacel">账单记录</div>
			<a-table :pagination="false" :data-source="info.list" row-key="id" :columns="[
				{title:'ID',dataIndex:'id'},
				{title:'类型',dataIndex:'operation_type_show',slots:{customRender:'operation_type_show'}},
				{title:'用户',dataIndex:'user',slots:{customRender:'user'}},
				{title:'交易单号',dataIndex:'trade.order_number'},
				{title:'名称',dataIndex:'description'},
				{title:'金额(元)',dataIndex:'change',slots:{customRender:'change'}},
				{title:'交易时间',dataIndex:'create_time'},
			]" >
				<template #operation_type_show="{record}">
					<span v-if="record.type == 1">增加可用</span>
					<span v-if="record.type == 2">减少可用</span>
					<span v-if="record.type == 3">增加冻结</span>
					<span v-if="record.type == 4">减少冻结</span>
				</template>
				<template #user="{record}">
					<div style="display: flex;align-items: center;">
						<img :src="record.trade.user_avatar" alt="" style="width: 30px;height: 30px;border-radius: 50%;margin-right: 4px;">
						<span>{{record.trade.user_name}}</span>
					</div>
				</template>
				<template #change="{record}">
					<span v-if="record.type == 1 || record.type == 3">+</span>
					<span v-else>-</span>
					<span>{{record.change}}</span>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getBillRecord(info.page,e)}"
					@change="(e)=>{getBillRecord(e,info.limit)}"
				/>
			</div>
		</div>
		
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import shopModel from '@/api/addons/shop';
import userModel from '@/api/user';
export default{
	setup(){
		const state = reactive({
			search:{
				time:[],
				order_type:'',
				status:'',
				order_number:''
			},
			info:{
				list:[],
				page:1,
				limit:10,
				count:0
			},
			shopInfo:null,
			money:null
		})

		shopModel.getShopUserDetail(res=>{
			state.shopInfo = res

			shopModel.getShopMoney(res.shop.shop_id,res=>{
				state.money = res
				getBillRecord(1,state.info.limit)
			})
		})

		function getBillRecord(page,limit){
			userModel.getMoneyTrade(page,limit,{user_id:state.money.id,type:2,...state.search},res=>{
				state.info = {limit,...res}
			})
		}

		return{
			...toRefs(state),
			getBillRecord
		}
	}
}
</script>

<style lang="scss">
	.sfb-search{
		padding-left: 20px;
	}
	.sfb-top{
		width: 100%;
		background: #fff;
		padding-bottom: 24px;
		
		&-money{
			width:90%;
			height: 130px;
			display: flex;
			padding: 24px;
			border-bottom: 1px solid #ebeef5;
			margin-bottom: 24px;
			
			&-li{
				width: 400px;
				height: 100%;
				
				>div:first-child{
					color: #999;
					font-size: 16px;
					margin-bottom: 12px;
				}
				.sfbt-price{
					span{
						font-size: 28px;
					}
				}
			}
		}
	}
</style>
